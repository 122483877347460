<script setup lang="ts">
import { PropType } from 'vue';

import { CardVariant } from '@viewModels/enums';

// Card Props
const props = defineProps({
  variant: {
    type: String as PropType<CardVariant>,
    default: CardVariant.Light,
  },
  hasBorder: {
    type: Boolean,
    default: false,
  },
  marginBottom: {
    type: Boolean,
    default: true,
  },
  narrowCard: {
    type: Boolean,
    default: false,
  },
  isSticky: {
    type: Boolean,
    default: false,
  },
  hasShadow: {
    type: Boolean,
    default: true,
  }
});

defineExpose({ props });

const variantStyles = {
  [CardVariant.Primary]: 'primary',
  [CardVariant.Secondary]: 'secondary',
  [CardVariant.Success]: 'success',
  [CardVariant.Danger]: 'danger',
  [CardVariant.Warning]: 'warning',
  [CardVariant.Light]: 'light',
  [CardVariant.Dark]: 'dark',
  [CardVariant.LightGray]: 'light-gray',
  [CardVariant.TransparentDark]: 'transparent-dark',
  [CardVariant.TransparentLight]: 'transparent-light',
};

function getCardClasses(variant: CardVariant, hasBorder: boolean): string {
  const baseClass = variantStyles[variant];
  // If hasBorder is true, return only the borderClass, otherwise return baseClass
  return hasBorder ? `${baseClass}--border` : baseClass;
}
</script>

<template>
  <div :class="[
    'container-card',
    getCardClasses(variant, hasBorder),
    {
      'mb-0': !marginBottom,
      'container-card--sticky': isSticky,
      'container-card--narrow': narrowCard,
      'container-card--shadow': hasShadow,
    },
  ]">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

// Mixin for generating card themes
@mixin card-theme($bg-color, $text-color, $border-color) {
  color: $text-color;
  background-color: $bg-color;

  &--border {
    color: $text-color;
    background-color: $bg-color;
    box-shadow: inset 0 0 0 1px $border-color;
  }
}

// Global Card Container
.container-card {
  padding: clamp($gap-mobile, 2vw, $gap-desktop);
  margin-bottom: clamp($gap-default, 2vw, $gap-desktop);
  border-radius: 10px;

  &--shadow {
    box-shadow: $box-shadow;
  }

  &--narrow {
    padding-top: $gap-mobile;
    padding-bottom: $gap-mobile;
  }

  &--sticky {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  &.mb-0 {
    margin-bottom: 0;
  }


  &.primary {
    @include card-theme(var(--tls-primary-color), var(--tls-primary-text), var(--tls-primary-300));
  }

  &.secondary {
    @include card-theme(var(--tls-secondary-color), var(--tls-secondary-text), var(--tls-secondary-500));
  }

  &.success {
    @include card-theme(var(--tls-success-color), var(--tls-success-text), color-mix(in oklab, var(--tls-success-color) 90%, black));
  }

  &.danger {
    @include card-theme(var(--tls-error-color), var(--tls-error-text), color-mix(in oklab, var(--tls-error-color) 90%, black));
  }

  &.warning {
    @include card-theme(var(--tls-warning-color), var(--tls-warning-text), color-mix(in oklab, var(--tls-warning-color) 90%, black));
  }

  &.light {
    @include card-theme(var(--tls-gray-50), var(--tls-gray-800), var(--tls-gray-300));
  }

  &.dark {
    @include card-theme(var(--tls-gray-800), var(--tls-gray-50), var(--tls-gray-700));
  }

  &.light-gray {
    @include card-theme(var(--tls-gray-200), var(--tls-gray-800), var(--tls-gray-400));
  }

  // Transparent Dark Theme
  &.transparent-dark {
    background: $black-opacity-50;
    backdrop-filter: blur(2px);

    &--border {
      background: $black-opacity-50;
      backdrop-filter: blur(2px);
      box-shadow: inset 0 0 0 1px var(--tls-gray-900);
    }
  }

  // Transparent Light Theme
  &.transparent-light {
    background: $white-opacity-50;
    backdrop-filter: blur(2px);

    &--border {
      background: $white-opacity-50;
      backdrop-filter: blur(2px);
      box-shadow: inset 0 0 0 1px var(--tls-gray-400);
    }
  }
}
</style>
