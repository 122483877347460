<script lang="ts" setup>
import { computed, onMounted, Ref, ref, useSlots, watch } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { getIconComponent } from '@utils/heroIconsMap';
import { ButtonSize, ButtonStyle, ButtonType, ButtonVariant, NotificationCountVariant } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import { ArrowPathIcon } from '@heroicons/vue/24/solid';
import NotificationCount from '@components/NotificationCount.vue';

interface ButtonProps {
  type?: ButtonType;
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  loading?: boolean;
  isBlockBtn?: boolean;
  btnStyle?: ButtonStyle;
  isOutlineBtn?: boolean;
  noBorder?: boolean;
  to?: RouteLocationRaw;
  iconName?: IconName;
  iconStyle?: IconStyle;
  iconPosition?: IconPosition;
  isIconBtn?: boolean;
  ariaLabel?: string;
  isActiveBtn?: boolean;
  isDropdownBtn?: boolean;
  hidden?: boolean;
  notificationCount?: number;
  notificationCountVariant?: NotificationCountVariant;
}

// Use the interface directly with defineProps
const props = defineProps<ButtonProps>();

// Delayed loading state
const delayedLoading = ref(false);
const loadingDelay = 0; // There was a 1-second delay after the loading prop was set - removing it for now so it aligns with loading bar and loading spinner

// Watch the loading prop and manage delayed loading state
watch(
  () => props.loading,
  (newValue) => {
    if (newValue) {
      delayedLoading.value = true;
    } else {
      setTimeout(() => {
        delayedLoading.value = false;
      }, loadingDelay);
    }
  }
);

// Define default props
const type = computed(() => props.type || ButtonType.Button);
const variant = computed(() => props.variant || ButtonVariant.Primary);
const isBlockBtn = computed(() => props.isBlockBtn || false);
// Compute the ariaLabel based on whether the button is icon-only
const computedAriaLabel = computed(() => {
  if (props.isIconBtn) {
    return props.ariaLabel || '';
  }
  return props.ariaLabel || '';
});
// Compute the icon position
const computedIconPosition = computed(() => {
  return props.iconPosition || IconPosition.Left;
});

// Define emitted events for parent communication
const emit = defineEmits<{
  (e: 'click', event: Event): void;
  (e: 'toggleDropdown', isOpen: boolean): void;
}>();

// Manage dropdown open/close state if used as a dropdown button
const isOpen = ref(false) as Ref<boolean>;

// Determine the tag to be rendered (button or router-link)
const buttonTag = computed(() => (props.to ? 'router-link' : 'button'));

// Flag to check if the default slot has content
const hasDefaultSlot = ref(false);

// Check for default slot content when component is mounted
onMounted(() => {
  const slots = useSlots();
  hasDefaultSlot.value = !!slots['default'];
});

// Emit click event and handle dropdown toggle
function handleClick(event: Event): void {
  emit('click', event);
  toggleDropdown();
}

// Toggle dropdown state if applicable (only if isDropdownBtn is true)
function toggleDropdown(): void {
  if (props.isDropdownBtn) {
    isOpen.value = !isOpen.value;
    emit('toggleDropdown', isOpen.value);
  }
}
</script>

<template>
  <component :is="buttonTag"
             v-show="!props.hidden"
             :aria-disabled="disabled || delayedLoading"
             :aria-label="computedAriaLabel"
             :class="[
               'btn',
               `btn--${variant || ButtonVariant.Dark}`,
               `btn--${btnStyle || ButtonStyle.Rounded}`,
               { [`btn--${size}`]: size },
               { 'btn--loading': delayedLoading, 'btn--mobile': isBlockBtn },
               { [`btn-outline--${variant || ButtonVariant.Dark}`]: isOutlineBtn },
               { 'btn--icon-only': isIconBtn },
               { 'btn--disabled': disabled && !to },
               { 'btn--active': isActiveBtn },
               { 'btn--dropdown': isDropdownBtn, open: isOpen },
               { 'btn--has-border': noBorder },
             ]"
             :disabled="disabled && !to"
             :to="to"
             :type="!to ? type || ButtonType.Button : null"
             @click="handleClick">
    <span ref="content"
          class="btn__content">
      <!-- Left icon or loading spinner -->
      <template v-if="iconName && computedIconPosition === IconPosition.Left">
        <ArrowPathIcon v-if="delayedLoading"
                       aria-label="Loading"
                       class="btn__spinner" />
        <component :is="getIconComponent(iconName as IconName, iconStyle as IconStyle)"
                   v-else
                   aria-hidden="true"
                   class="btn__icon" />
      </template>

      <!-- Loading spinner when no icon on the left -->
      <template v-if="!iconName && delayedLoading">
        <ArrowPathIcon aria-label="Loading"
                       class="btn__spinner" />
      </template>

      <!-- Text slot -->
      <slot v-if="!isIconBtn" />

      <!-- Notification Count Slot -->
      <NotificationCount v-if="notificationCount && !isIconBtn"
                         :count="notificationCount"
                         :variant="notificationCountVariant" />

      <!-- Right icon or loading spinner -->
      <template v-if="iconName && computedIconPosition === IconPosition.Right">
        <ArrowPathIcon v-if="delayedLoading"
                       aria-label="Loading"
                       class="btn__spinner" />
        <component :is="getIconComponent(iconName as IconName, iconStyle as IconStyle)"
                   v-else
                   aria-hidden="true"
                   class="btn__icon" />
      </template>
    </span>
  </component>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

// Mixin for button styles
@mixin button-style(
  $bg-color,
  $hover-color,
  $text-color
) {
  color: $text-color;
  background-color: $bg-color;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $hover-color;
  }

  &.btn--active {
    pointer-events: none;
    background-color: $hover-color;
  }
}

// Mixin for outline button styles
@mixin button-outline-style(
  $text-color,
  $border-color,
  $hover-bg-color,
  $hover-text-color
) {
  color: $text-color;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px $border-color;

  &:hover,
  &:focus {
    color: $hover-text-color;
    text-decoration: none;
    background-color: $hover-bg-color;
  }

  &.btn--active {
    pointer-events: none;
    color: $hover-text-color;
    background-color: $hover-bg-color;
  }
}

.btn {
  $root: &;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: min-content;

  min-height: 40px;
  max-height: 40px;
  padding: 10px 20px;
  font-size: 1rem;
  white-space: nowrap;
  cursor: pointer;
  border: none;
  outline: none;
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    box-shadow 0.3s ease,
    outline 0.3s ease;

  &--rounded {
    border-radius: 5px;
  }

  &--pill {
    border-radius: 50px;
  }

  &--rectangle {
    border-radius: 0;
  }

  &__content {
    display: inline-flex;
    column-gap: 8px;
    align-items: center;
    line-height: 1.2;
  }

  &--icon-only {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    padding: 0;

    & #{$root}__icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    &#{$root}--small {
      width: 32px;
      min-width: 32px;
      height: 32px;

      & #{$root}__icon {
        width: 1.4rem;
        height: 1.4rem;
      }
    }

    &#{$root}--large {
      width: 54px;
      min-width: 54px;
      height: 54px;

      & #{$root}__icon {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  &--dropdown {
    & #{$root}__icon {
      transition: transform 0.15s ease;
      transform: rotate(0deg);
    }

    &.open {
      & #{$root}__icon {
        transform: rotate(180deg);
      }
    }
  }

  &--has-border {
    border-radius: 0;
    box-shadow: unset !important;
  }

  &--primary {
    @include button-style(var(--tls-primary-color), var(--tls-primary-hover), var(--tls-primary-text));
  }

  &--secondary {
    @include button-style(var(--tls-secondary-color), var(--tls-secondary-hover), var(--tls-secondary-text));
  }

  &--success {
    @include button-style(var(--tls-success-color), var(--tls-success-hover), var(--sucess-text));
  }

  &--danger {
    @include button-style(var(--tls-error-color), var(--tls-error-hover), var(--tls-error-text));
  }

  &--warning {
    @include button-style(var(--tls-warning-color), var(--tls-warning-hover), var(--tls-warning-text));
  }

  &--info {
    @include button-style(var(--tls-info-color), var(--tls-info-hover), var(--tls-info-text));
  }

  &--light {
    @include button-style(var(--tls-gray-300), var(--tls-gray-200), var(--tls-gray-900));
  }

  &--dark {
    @include button-style(var(--tls-gray-600), var(--tls-gray-800), var(--tls-gray-50));
  }

  &--transparent {
    @include button-style(transparent, transparent, inherit);
  }

  &--link {
    color: inherit;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
      filter: #{'invert(20%)'};
      box-shadow: none;
    }

    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  &--loading {
    cursor: not-allowed;
    opacity: 0.65;
  }

  &--small {
    min-width: 70px;
    min-height: 32px;
    max-height: 32px;
    padding: 8px 10px;
    font-size: 0.875rem;

    & #{$root}__icon,
    & #{$root}__spinner {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  &--large {
    min-width: 120px;
    min-height: 54px;
    max-height: 54px;
    padding: 15px 25px;
    font-size: 1.25rem;

    & #{$root}__icon,
    & #{$root}__spinner {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.65;
  }

  button[disabled] {
    pointer-events: none;
    opacity: 0.65;
  }

  &__spinner {
    width: 1.3rem;
    height: 1.3rem;
    animation: rotate-360 0.75s linear infinite;
  }

  &__icon {
    width: 1.3rem;
    height: 1.3rem;

    &.btn--small & {
      width: 1rem;
      height: 1rem;
    }

    &.btn--large & {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &--mobile {
    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
    }
  }

  /* Outline button styles */
  &.btn-outline--primary {
    @include button-outline-style(var(--tls-primary-color), var(--tls-primary-color), var(--tls-primary-color), var(--tls-primary-text));
  }

  &.btn-outline--secondary {
    @include button-outline-style(var(--tls-secondary-color), var(--tls-secondary-color), var(--tls-secondary-color), var(--tls-secondary-text));
  }

  &.btn-outline--success {
    @include button-outline-style(var(--tls-success-color), var(--tls-success-color), var(--tls-success-color), var(--tls-success-text));
  }

  &.btn-outline--danger {
    @include button-outline-style(var(--tls-error-color), var(--tls-error-color), var(--tls-error-color), var(--tls-error-text));
  }

  &.btn-outline--warning {
    @include button-outline-style(var(--tls-warning-color), var(--tls-warning-color), var(--tls-warning-color), var(--tls-warning-text));
  }

  &.btn-outline--info {
    @include button-outline-style(var(--tls-info-color), var(--tls-info-color), var(--tls-info-color), var(--tls-info-text));
  }

  &.btn-outline--light {
    @include button-outline-style(var(--tls-gray-300), var(--tls-gray-300), var(--tls-gray-300), var(--tls-gray-600));
  }

  &.btn-outline--dark {
    @include button-outline-style(var(--tls-gray-600), var(--tls-gray-600), var(--tls-gray-600), var(--tls-gray-50));
  }

  &.btn-outline--transparent {
    @include button-outline-style(transparent, transparent, transparent, inherit);
  }

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 2px var(--tls-secondary-color);
  }
}
</style>
