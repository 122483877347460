<script lang="ts" setup>
import { PropType } from 'vue';
import * as client from '@gabrielcam/api-client';
import { BadgeVariant } from '@viewModels/enums';
import BadgeComponent from '@components/BadgeComponent.vue';
import { dateTimeFormat } from '@utils/date';
import { useApplicationStore } from '@stores/application';

defineProps({
  logs: {
    type: Object as PropType<client.Log[]>,
    required: false
  },
});

const applicationStore = useApplicationStore();
const { activeUser } = applicationStore;

function getBadgeVariant(status: client.LogLevel): BadgeVariant {
  const variantMap: { [key in client.LogLevel]: BadgeVariant } = {
    INFO: BadgeVariant.Info,
    WARN: BadgeVariant.Warning,
    ERROR: BadgeVariant.Danger,
  };
  return variantMap[status];
}
</script>

<template>
  <div class="log-grid-container">
    <div class="log-grid">
      <div class="log-grid-container">
        <div class="log-grid">
          <div v-for="(item, index) in logs" :key="index" class="log-grid__item">
            <div class="log-grid__index">
              <span class="log-grid__value">
                {{ item.message }}
              </span>
            </div>

            <div class="log-grid__info">
              <BadgeComponent class="log-grid__timestamp" :variant="BadgeVariant.Light">
                {{ dateTimeFormat(activeUser?.timezone).format(new Date(item.timestamp)) }}
              </BadgeComponent>

              <BadgeComponent class="log-grid__badge"
                              :is-pill="true"
                              :variant="getBadgeVariant(item.level) as BadgeVariant">
                {{ item.level }}
              </BadgeComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/variables' as *;

.log-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: clamp($gap-mobile, 3vw, $gap-desktop);
  font-size: 14px;

  &__item {
    display: flex;
    flex-direction: column;
    gap: 5px 20px;
    padding: 10px 0;
    border-top: 1px solid var(--tls-gray-200);

    &:nth-child(1) {
      border-top: 0;
    }
  }

  &__index {
    display: flex;
    gap: 10px;
  }

  &__info {
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }

  &__badge {
    min-width: 60px;
    margin-left: auto;
  }

  &__timestamp {
    min-width: 150px;
    white-space: nowrap;
  }

  &__value {
    word-break: break-all;
    overflow-wrap: break-word;
  }
}
</style>
