<script setup lang="ts">
import { computed } from 'vue';
import { NotificationCountVariant } from '@viewModels/enums';

interface NotificationCountProps {
  count: number;
  maxCount?: number;
  variant?: NotificationCountVariant;
}

const props = withDefaults(defineProps<NotificationCountProps>(), {
  maxCount: 99,
  variant: NotificationCountVariant.Success
});

const displayCount = computed(() => (props.count > props.maxCount ? `${props.maxCount}+` : props.count));
</script>

<template>
  <span v-if="count > 0" :class="['notification-count', `notification-count--${variant}`]">
    {{ displayCount }}
  </span>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.notification-count {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  aspect-ratio: 1;
  padding: 0;
  font-size: 0.75em;
  border-radius: 20px;

  &--dark {
    color: var(--tls-gray-100);
    background-color: var(--tls-gray-900);
  }

  &--light {
    color: var(--tls-gray-800);
    background-color: var(--tls-gray-50);
  }

  &--success {
    color: var(--tls-success-text);
    background-color: var(--tls-success-color);
  }

  &--danger {
    color: var(--tls-error-text);
    background-color: var(--tls-error-color);
  }

  &--warning {
    color: var(--tls-warning-text);
    background-color: var(--tls-warning-color);
  }
}
</style>
