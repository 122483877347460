<script lang="ts" setup>
import { getIconComponent } from '@utils/heroIconsMap';

import { ButtonVariant } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import ButtonComponent from '@components/ButtonComponent.vue';
import Heading from '@components/Heading.vue';

// Interface for props
interface EmptyStateProps {
  headingText: string | '';
  headingLevel?: '1' | '2' | '3' | '4' | '5' | '6';
  strapLine?: string | '';
  iconName: IconName | IconName.DocumentIcon;
  iconStyle: IconStyle | IconStyle.Outline;
  buttonText?: string | '';
  buttonVariant?: ButtonVariant | ButtonVariant.Dark;
  to?: Record<string, unknown>;
}

// Define the props
const props = defineProps<EmptyStateProps>();

// Set the defaults
const headingLevel = props.headingLevel || '2';

const emits = defineEmits<{
  (e: 'btnClick'): void;
}>();

const emptyStateBtnAction = (): void => {
  emits('btnClick');
};
</script>

<template>
  <div class="empty-state">
    <component :is="getIconComponent(iconName, iconStyle)"
               v-if="iconName"
               class="empty-state__icon" />
    <Heading :level="headingLevel">
      {{ headingText }}
    </Heading>

    <p v-if="strapLine">
      {{ strapLine }}
    </p>

    <slot />

    <ButtonComponent v-if="buttonText && buttonVariant && to"
                     :to="to"
                     :variant="buttonVariant">
      {{ buttonText }}
    </ButtonComponent>
    <ButtonComponent v-else-if="buttonText && buttonVariant"
                     :variant="buttonVariant"
                     @click="emptyStateBtnAction">
      {{ buttonText }}
    </ButtonComponent>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.empty-state {
  display: flex;
  flex-direction: column;
  gap: $gap-default;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 300px;
  min-height: 300px;
  padding: clamp($gap-mobile, 3vw, $gap-desktop);
  margin-bottom: $margin-bottom;
  text-align: center;
  border: 3px dashed var(--tls-gray-300);
  border-radius: 10px;

  @media screen and (width >= $breakpoint-lg) {
    width: inherit;
  }

  &__icon {
    width: 50px;
    height: 50px;
    opacity: 0.25;
  }
}

.table-empty .empty-state {
  margin-bottom: 0;
}
</style>
