<script setup lang="ts">
import { computed, nextTick, onMounted, Ref, ref, watch } from 'vue';
import { VIEW_MODE } from '@stores/irisViewerStore';

// @ts-ignore
import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/style.css';

interface ScaleToOptions {
  allowChangeEvent: boolean;
  originX?: string | number;
  originY?: string | number;
  relativeTo?: string;
}

interface Styles {
  [property: string]: string;
}

interface Props {
  alt?: string;
  leftImageSrc: string;
  rightImageSrc?: string;
  mode?: VIEW_MODE,
  maxScale?: number;
  opacity: number;
}

interface ZoomDetails {
  scale: number;
  x: number;
  y: number;
}

const props = withDefaults(defineProps<Props>(), {
  alt: 'image',
  imageSrc: '',
  maxScale: 50,
  rightImageSrc: '',
  mode: VIEW_MODE.SINGLE,
  opacity: 50,
});

const emit = defineEmits<{
  (e: 'change', value: ZoomDetails): void;
  (e: 'imageLoaded'): void;
}>();

const clicked = ref(false);
const drag = ref({
  active: false,
  end: {
    x: 0,
    y: 0,
  },
  start: {
    x: 0,
    y: 0,
  },
});
const frame = ref({
  height: 1,
  width: 1,
});
const image = ref({
  height: 1,
  width: 1,
});
const imageFrame = ref() as Ref<HTMLElement>;
const leftImageElement = ref(null) as Ref<HTMLImageElement | null>;
const rightImageElement = ref(null) as Ref<HTMLImageElement | null>;
const scaleAlert = ref(0);
const zoom = ref({
  scale: 1,
  x: 0,
  y: 0,
});

onMounted(() => {
  init();
  window.addEventListener('resize', () => init());
});

watch(
  () => props.rightImageSrc,
  () => {
    if (props.rightImageSrc == undefined) {
      resetImageClipPath();
      return;
    }
    nextTick(resetImageComparison);
  },
);
const imageCompleted = computed(() => {
  return leftImageElement.value?.complete;
});

const imageStyle = computed({
  get(): Styles {
    const width = image.value.width * zoom.value.scale + 'px';
    const cursor = 'move';
    return {
      cursor,
      width,
      maxWidth: image.value.width * props.maxScale + 'px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: transform(-zoom.value.x, -zoom.value.y),
    };
  },
  set(style) {
    return style;
  },
});

const rightImageStyle = computed({
  get(): Styles {
    return {
      ...imageStyle.value,
      ...{
        opacity: `${props.opacity}%`,
      },
    };
  },
  set(style) {
    return style;
  },
});

const limits = computed(() => {
  const a = frame.value.width;
  const b = frame.value.height;
  const i = image.value.width;
  const j = image.value.height;
  const c = a / b;
  const k = i / j;

  let limits = { min: 0, max: 0 };
  let x = { ...limits };
  let y = { ...limits };

  if (c > k) {
    y = {
      min: frame.value.height / 2,
      max: image.value.height * zoom.value.scale - frame.value.height / 2,
    };

    if (zoom.value.scale > frame.value.width / image.value.width) {
      x = {
        min: frame.value.width / 2,
        max: image.value.width * zoom.value.scale - frame.value.width / 2,
      };
    } else {
      x = {
        min: (image.value.width * zoom.value.scale) / 2,
        max: (image.value.width * zoom.value.scale) / 2,
      };
    }
  } else {
    x = {
      min: frame.value.width / 2,
      max: image.value.width * zoom.value.scale - frame.value.width / 2,
    };

    if (zoom.value.scale > frame.value.height / image.value.height) {
      y = {
        min: frame.value.height / 2,
        max: image.value.height * zoom.value.scale - frame.value.height / 2,
      };
    } else {
      y = {
        min: (image.value.height * zoom.value.scale) / 2,
        max: (image.value.height * zoom.value.scale) / 2,
      };
    }
  }
  return { x, y };
});

let doubleClickId: ReturnType<typeof setTimeout>;

function imageClick(event: any): void {
  if (!clicked.value) {
    clicked.value = true;
    doubleClickId = setTimeout(() => {
      clicked.value = false;
    }, 200);
  } else {
    scaleTo(zoom.value.scale * 1.38, {
      originX: event.offsetX,
      originY: event.offsetY,
      relativeTo: 'content',
      allowChangeEvent: true,
    });
    clicked.value = false;
    clearTimeout(doubleClickId);
  }
}

const imageStartPos = ref({ x: 0, y: 0 });

function startImageDragging(event: MouseEvent): void {
  if (imageComparisonBarIsDragging.value) return;
  event.preventDefault();
  drag.value.active = true;
  drag.value.start.x = zoom.value.x;
  drag.value.start.y = zoom.value.y;
  imageStartPos.value.x = event.clientX;
  imageStartPos.value.y = event.clientY;
  window.addEventListener('mousemove', imageDrag);
  window.addEventListener('mouseup', stopImageDragging);
}

function stopImageDragging(): void {
  window.removeEventListener('mousemove', imageDrag);
  window.removeEventListener('mouseup', stopImageDragging);
  drag.value.active = false;
}

function imageDrag(event: MouseEvent): void {
  if (imageComparisonBarIsDragging.value) return;
  event.preventDefault();
  setTransform(
    {
      scale: zoom.value.scale,
      x: drag.value.start.x + imageStartPos.value.x - event.clientX,
      y: drag.value.start.y + imageStartPos.value.y - event.clientY,
    },
    true,
  );
}

function touchDrag(e: TouchEvent, touch: any): void {
  if (imageComparisonBarIsDragging.value) return;
  if (e.touches == undefined) return;
  if (e.touches.length == 0) return;

  const transform = {
    scale: zoom.value.scale,
    x: drag.value.start.x + touch.clientX - e.touches[0]!.clientX,
    y: drag.value.start.y + touch.clientY - e.touches[0]!.clientY,
  };
  setTransform(transform, true);
}

function touchStart(event: TouchEvent): void {
  if (imageComparisonBarIsDragging.value) return;
  event.preventDefault();
  const touch = event.touches[0];
  imageClick(touch);

  drag.value.start.x = zoom.value.x;
  drag.value.start.y = zoom.value.y;

  document.ontouchmove = (e) => touchDrag(e, touch);
}

function touchEnd(event: TouchEvent): void {
  if (imageComparisonBarIsDragging.value) return;
  event.preventDefault();
  document.ontouchmove = null;
}

function init(): void {
  const waitForImage = setInterval(() => {
    if (leftImageElement.value?.complete) {
      frame.value.height = imageFrame.value.clientHeight;
      frame.value.width = imageFrame.value.clientWidth;

      const x = leftImageElement.value.naturalWidth;
      const y = leftImageElement.value.naturalHeight;

      const a = frame.value.width / frame.value.height;
      const b = x / y;

      let imageHeight = 0;
      let imageWidth = 0;

      if (a > b) {
        imageHeight = frame.value.height;
        imageWidth = imageHeight * b;
      } else {
        imageWidth = frame.value.width;
        imageHeight = imageWidth / b;
      }

      image.value.height = imageHeight;
      image.value.width = imageWidth;

      // initial values for draggable overlay to be centered
      draggable.value.top = (frame.value.height - draggable.value.height) / 2;
      draggable.value.left = (frame.value.width - draggable.value.width) / 2;

      const newZoom = { scale: 1, x: image.value.width / 2, y: image.value.height / 2 };
      zoom.value = newZoom;
      setTransform(newZoom, true);
      clearInterval(waitForImage);
    }
  }, 50);
}

function scaleTo(scale: number, options: ScaleToOptions): void {
  const scaleFactor = scale / zoom.value.scale;
  let x = options.originX;
  let y = options.originY;
  let newX: number = zoom.value.x;
  let newY: number = zoom.value.y;

  if (typeof x != 'undefined') {
    if (typeof x === 'string' && x.slice(x.length - 1) === '%') {
      if (options.relativeTo === 'content') {
        newX = (Number(x.split('%')[0]) / 100) * image.value.width * scale;
      } else {
        newX = zoom.value.x * scaleFactor;
      }
    }
    if (typeof x === 'number') {
      newX = x * scaleFactor;
    }
  }

  if (typeof y != 'undefined') {
    if (typeof y === 'string' && y.slice(y.length - 1) === '%') {
      if (options.relativeTo === 'content') {
        newY = (Number(y.split('%')[0]) / 100) * image.value.height * scale;
      } else {
        newY = zoom.value.y * scaleFactor;
      }
    }
    if (typeof y === 'number') {
      newY = y * scaleFactor;
    }
  }

  setTransform({ scale, x: newX, y: newY }, true);
}

function scrollZoom(event: WheelEvent): void {
  event.preventDefault();

  const scale = zoom.value.scale - event.deltaY * 0.01;

  scaleTo(scale, {
    originX: '50%',
    originY: '50%',
    relativeTo: 'container',
    allowChangeEvent: true,
  });
}

function setTransform({ scale, x, y }: { scale: number; x: number; y: number }, allowChangeEvent = false): void {
  if (scale < 1 || scale > props.maxScale) {
    if (!scaleAlert.value) {
      scaleAlert.value = 1;
      setTimeout(() => {
        scaleAlert.value = 0;
      }, 1000);
    }
  } else {
    zoom.value.scale = scale;

    if (x < limits.value.x.min) {
      x = limits.value.x.min;
    } else if (x > limits.value.x.max) {
      x = limits.value.x.max;
    }

    if (y < limits.value.y.min) {
      y = limits.value.y.min;
    } else if (y > limits.value.y.max) {
      y = limits.value.y.max;
    }

    zoom.value = { scale, x, y };
    allowChangeEvent ? emit('change', zoom.value) : null;
  }
  nextTick(resetImageComparison);
}

function transform(x: number, y?: number): string {
  let translate = '';
  if (x && y) {
    translate = `translate(${x + 'px'}, ${y + 'px'})`;
  }
  if (!x) {
    translate = `translateY(${y + 'px'})`;
  }
  if (!y) {
    translate = `translateX(${x + 'px'})`;
  }
  return translate;
}

const imageComparisonBarIsDragging = ref<boolean>(false);

const dragBar = ref<HTMLElement>();

function startDragComparisonBar(event: MouseEvent): void {
  event.preventDefault();
  imageComparisonBarIsDragging.value = true;
  window.addEventListener('mousemove', handleDrag);
  window.addEventListener('mouseup', stopDrag);
}

function handleDrag(event: MouseEvent): void {
  if (imageComparisonBarIsDragging.value == false) return;
  setImageClipPosition(event.clientX);
}

function setBarPosition(clientX: number): void {
  if (dragBar.value == undefined) return;
  const containerRect = imageFrame.value.getBoundingClientRect();
  const containerLeft = containerRect.left;
  const containerWidth = imageFrame.value.offsetWidth;
  const dragBarWidth = dragBar.value.offsetWidth;
  const dragBarHalfWidth = dragBarWidth / 2;
  const maxPosition = containerWidth - dragBarWidth;
  let newPosition = clientX - containerLeft - dragBarHalfWidth;
  newPosition = Math.max(0, Math.min(maxPosition, newPosition));
  dragBar.value.style.left = `${newPosition}px`;
}

function setImageClipPosition(clientX?: number): void {
  if (!rightImageElement.value) return;
  if (dragBar.value == undefined) return;
  if (clientX == undefined) clientX = dragBar.value.clientLeft;
  const containerRect = rightImageElement.value.getBoundingClientRect();
  const containerLeft = containerRect.left;
  const containerWidth = rightImageElement.value.offsetWidth;
  const dragBarWidth = dragBar.value.offsetWidth;
  const dragBarHalfWidth = dragBarWidth / 2;
  const maxPosition = containerWidth - dragBarWidth;

  let newPosition = clientX - containerLeft - dragBarHalfWidth;
  newPosition = Math.max(0, Math.min(maxPosition, newPosition));

  rightImageElement.value.style.clipPath = `inset(0px 0px 0px ${newPosition}px)`;
  if (newPosition <= 0) return;
  if (newPosition >= maxPosition) return;
  setBarPosition(clientX);
}

function resetImageComparison(): void {
  if (dragBar.value == undefined) return;
  const dragBarWidth = dragBar.value.offsetWidth;
  const dragBarHalfWidth = dragBarWidth / 2;
  const containerRect = imageFrame.value.getBoundingClientRect();
  const containerLeft = containerRect.left;
  setImageClipPosition(containerLeft + containerRect.width / 2 + dragBarHalfWidth);
  dragBar.value.style.left = `50%`;
}

function resetImageClipPath(): void {
  if (!rightImageElement.value) return;
  rightImageElement.value.style.clipPath = `inset(0px 0px 0px 0px)`;
  rightImageElement.value.style.opacity = '100%';
}

function stopDrag(): void {
  window.removeEventListener('mousemove', handleDrag);
  window.removeEventListener('mouseup', stopDrag);
  imageComparisonBarIsDragging.value = false;
}

defineExpose({
  init,
  scaleTo,
  setTransform,
  imageCompleted,
});

const draggable = ref<{ left: number, top: number, height: number, width: number }>(
  {
    left: 150,
    top: 150,
    height: 200,
    width: 200,
  },
);

function onResizeDraggable(left: number, top: number, width: number, height: number): void {
  draggable.value = { left, top, width, height };
}

function onMoveDraggable(left: number, top: number): void {
  draggable.value = {
    ...draggable.value,
    ...{ left: left, top: top },
  };
}

watch(() => [draggable.value, zoom.value, props.mode],
  () => {
    switch (props.mode) {
      case VIEW_MODE.BLEND: {
        resetImageClipPath();
        break;
      }

      case VIEW_MODE.OVERLAY: {
        setImageOverlaySize(draggable.value.left, draggable.value.top, draggable.value.width, draggable.value.height);
        break;
      }

      case VIEW_MODE.COMPARE: {
        nextTick(resetImageComparison);
        break;
      }
    }
  },
);

function setImageOverlaySize(left: number, top: number, width: number, height: number): void {
  nextTick(() => {
    if (!rightImageElement.value) return;
    const frame = imageFrame.value.getBoundingClientRect();
    const mainImage = leftImageElement.value!.getBoundingClientRect();
    const setTop = (top + frame.top) - mainImage.top;
    const setLeft = (left + frame.left) - mainImage.left;
    const setBottom = mainImage.bottom - (top + frame.top + height);
    const setRight = mainImage.right - (left + width + frame.left);

    rightImageElement.value.style.clipPath = `inset(${setTop}px ${setRight}px ${setBottom}px ${setLeft}px)`;
  });
}

</script>

<template>
  <div id="imageFrame"
       ref="imageFrame"
       class="viewer__image-frame"
       @wheel="scrollZoom">
    <VueDraggableResizable v-if="rightImageSrc && mode === VIEW_MODE.OVERLAY"
                           :w="draggable.width"
                           :h="draggable.height"
                           :parent="true"
                           :z="10"
                           :x="draggable.left"
                           :y="draggable.top"
                           style="border: solid 1px red"
                           @dragging="onMoveDraggable"
                           @resizing="onResizeDraggable" />
    <div class="image-holder">
      <img ref="leftImageElement"
           class="left-image"
           :src="leftImageSrc"
           :alt="alt"
           :style="imageStyle"
           draggable="false"
           @click="(e) => imageClick(e)"
           @mousedown="startImageDragging"
           @touchstart="(e) => touchStart(e)"
           @touchend="touchEnd"
           @load="emit('imageLoaded')">

      <img v-if="rightImageSrc"
           ref="rightImageElement"
           class="right-image"
           :src="rightImageSrc"
           :alt="alt"
           :style="rightImageStyle"
           draggable="false"
           @click="(e) => imageClick(e)"
           @mousedown="startImageDragging"
           @touchstart="(e) => touchStart(e)"
           @touchend="touchEnd"
           @load="emit('imageLoaded')">

      <div v-if="rightImageSrc && mode === VIEW_MODE.COMPARE"
           ref="dragBar"
           class="drag-bar"
           @mousedown="startDragComparisonBar" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.viewer__image-frame {
  position: relative;
  width: 100%;
  height: 100%;

  & img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: contain;
  }

  & .drag-bar {
    position: absolute;
    top: 0;
    left: 50%;
    width: 6px;
    height: 100%;
    cursor: ew-resize;
    background: var(--tls-gray-50);

    &::after {
      position: absolute;
      top: 50%;
      left: -15px;
      width: 36px;
      height: 36px;
      margin-top: -15px;
      content: '';
      background: var(--tls-gray-50);
      border: 3px var(--tls-gray-50) solid;
      border-radius: 50%;
    }
  }
}
</style>
