<script setup lang="ts">
import { ref } from 'vue';

import Heading from '@components/Heading.vue';

const gabrielCamLogo = new URL('/src/assets/logos/gabrielcam-mark.png', import.meta.url).href;
const iris5Logo = new URL('/src/assets/logos/iris5-inverted-logo.png', import.meta.url).href;

const hostname = window.location.hostname;
const logoSrc = ref<string>('');

// Iris5 logo
if (hostname.includes('iris5')) {
  logoSrc.value = iris5Logo;
  // Gabrielcam logo for hub or localhost
} else if (hostname.includes('hub') || hostname.includes('localhost')) {
  logoSrc.value = gabrielCamLogo;
  // Fallback logo
} else {
  logoSrc.value = gabrielCamLogo;
}
</script>

<template>
  <section class="account-container">
    <img :src="logoSrc"
         alt=""
         aria-hidden="true"
         width="480">
    <Heading level="1"
             :has-bottom-margin="true">
      {{ $route.meta['title'] }}
    </Heading>

    <slot />
  </section>
</template>

<style lang="scss">
@use '@scss/variables' as *;

.account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  padding: $gap-desktop clamp($gap-mobile, 2vw, $gap-desktop) $gap-desktop;
  margin: 0;

  & img {
    width: 240px;
    max-width: 100%;
  }

  .account-form {
    width: min(100%, 450px);
    padding: 30px clamp($gap-mobile, 2vw, $gap-desktop);
    margin-bottom: $margin-bottom;
    color: var(--tls-gray-50);
    background: var(--tls-gray-900);
    border: 1px solid var(--tls-gray-600);
    border-radius: 10px;
    box-shadow: $box-shadow;

    &--content {
      margin-bottom: $margin-bottom;
    }

    &--success {
      color: var(--tls-gray-50);
      background: var(--tls-success-color);
      border-color: var(--tls-success-color);
      opacity: 0;
      animation: fadeIn 0.3s ease-out forwards;
    }
  }

  .account-link-container {
    display: flex;
    justify-content: space-evenly;
    width: min(100%, 450px);
    margin-bottom: $margin-bottom;

    & .account-link {
      &:link,
      &:visited {
        color: var(--tls-gray-50);
        text-decoration: none;
      }

      &:hover,
      &:focus,
      &:focus-visible {
        color: var(--tls-gray-300);
        text-decoration: underline;
        outline: none;
      }
    }
  }

  .account-footer {
    display: flex;
    flex-direction: column-reverse;
    gap: $gap-default;

    @media screen and (min-width: $breakpoint-lg) {
      flex-direction: row;
    }
  }
}
</style>
