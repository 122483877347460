<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { ErrorMessage, useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import * as client from '@gabrielcam/api-client';
import { BreadcrumbPaths, ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import Heading from '@components/Heading.vue';
import { useRoute, useRouter } from 'vue-router';
import { useApplicationStore } from '@stores/application';

export interface TransferSettingFormInterface {
  transferSetting: string,
  path: string,
}

// Stores
const applicationStore = useApplicationStore();
const route = useRoute();
const router = useRouter();

// View
const viewId = route.params['id'] as string;

// Data
const transferSettingOptions = ref<client.TransferSetting[]>([]);

// Loading
const isLoading = ref<boolean>(true);
const isSubmitting = ref(false);

// Form Validation Rules
const { handleSubmit, meta } = useForm<TransferSettingFormInterface>({
  validationSchema: yup.object().shape({
    transferSetting: yup
      .string()
      .required('Setting is required'),
    path: yup
      .string()
      .required('Path is required'),
  }),
});

// Form Values
const { value: transferSettingValue, meta: transferSettingMeta } = useField<string>('transferSetting', 'transferSetting');
const { value: pathValue, meta: pathMeta } = useField<string>('path', 'path');

// Submit Form
const onSubmit = handleSubmit(async (values) => {
  isSubmitting.value = true;
  try {
    await client.updateViewByIdSetTransfer({
      viewId: viewId,
      requestBody: {
        transferSetting: values.transferSetting,
        path: values.path,
      },
    });

    // Show success notification
    applicationStore.publishSuccessNotification({
      text: 'Successfully updated transfer settings.',
      autoCloseMs: 3000,
    });

    // Redirect to ViewTransfers
    router.push({ name: PageNames.ViewTransfers, params: { id: viewId } });

  } catch (error) {
    console.error(error);
    applicationStore.publishErrorNotification({ text: 'Error updating transfer settings.' });
  } finally {
    isSubmitting.value = false;
  }
});

onMounted(async () => {
  isLoading.value = true;
  const response = await client.listTransferSettings();
  transferSettingOptions.value = response.data;
  isLoading.value = false;
});

// Cancel Button
const cancelBtn = (): void => {
  const routerHistory = router.options.history;
  const backUrl = routerHistory.state['back'];
  // If previous route is login, navigate to /views/:id/transfers
  if (typeof backUrl === 'string' && backUrl.startsWith('/login?continueUrl=')) {
    router.push(BreadcrumbPaths.ViewTransfers.replace(':id', viewId) as string);
  } else if (routerHistory.state['back']) {
    // If there's a valid previous route, go back
    router.go(-1);
  } else {
    router.push(BreadcrumbPaths.Views as string);
  }
};
</script>

<template>
  <form @submit="onSubmit">
    <div class="field-group">
      <div class="field-group-info">
        <Heading level="3">
          Assign View Transfer
        </Heading>
        <p>Assign a transfer setting to your view.</p>
      </div>

      <div class="fields">
        <div class="row-half">
          <div class="field">
            <span class="label">Transfer setting</span>
            <v-select v-model="transferSettingValue"
                      input-id="ftp-type"
                      aria-label="FTP Type"
                      placeholder="Select transfer setting"
                      label="name"
                      :clearable="false"
                      :searchable="false"
                      :options="transferSettingOptions"
                      :reduce="(value: any) => value.id"
                      :class="{ 
                        'input-error': transferSettingMeta.touched && !transferSettingMeta.valid,
                        'input-valid': transferSettingMeta.touched && transferSettingMeta.valid
                      }"
                      @search="transferSettingMeta.touched = true" />
            <ErrorMessage name="transferSetting" class="message message-error" as="p" />
          </div>

          <div class="field">
            <label for="server-address">Path</label>
            <input id="server-address"
                   v-model="pathValue"
                   autocomplete="off"
                   type="text"
                   :class="{ 
                     'input-error': pathMeta.touched && !pathMeta.valid,
                     'input-valid': pathMeta.touched && pathMeta.valid
                   }"
                   @input="pathMeta.touched = true">
            <ErrorMessage name="path" class="message message-error" as="p" />
          </div>
        </div>
      </div>
    </div>

    <ButtonContainer>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       :is-outline-btn="true"
                       @click="cancelBtn">
        Cancel
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :loading="isLoading"
                       :disabled="isSubmitting || (!meta.valid && meta.touched)">
        Create
      </ButtonComponent>
    </ButtonContainer>
  </form>
</template>
