<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import * as client from '@gabrielcam/api-client';
import { ChevronRightIcon } from '@heroicons/vue/24/solid';

// Route and View Information
const route = useRoute();
const viewId = route.params['id'] as string;
const currentView = ref<client.View>();
const currentViewName = ref<string>();

// Project and Client Information
const linkedProject = ref<client.Project | undefined>();
const linkedClient = ref<client.Client | undefined>();

/**
 * Fetches view data by using the viewId (param) and updates the current view with the linked project, client and view name.
 *
 * @returns A promise that resolves when the view has been fetched.
 */
async function fetchView(): Promise<void> {
  try {
    const view = await client.getViewById({
      viewId,
      includes: [client.Resources_Client.CLIENT, client.Resources_Project.PROJECT],
    });

    if (view) {
      currentView.value = view;
      currentViewName.value = view.name;
      linkedProject.value = view.includes?.project;
      linkedClient.value = view.includes?.client;
    }
  } catch (error) {
    throw error;
  }
}

onMounted(async () => {
  await fetchView();
});

// Loading State for the Breadcrumbs so we don't get CLS
const isBreadcrumbLoaded = computed(() => {
  // Return any part of the breadcrumb if available
  return !!(linkedClient.value?.name || linkedProject.value?.name || currentView.value?.name);
});

const breadcrumb = computed(() => {
  const parts: string[] = [];
  if (linkedClient.value?.name) parts.push(linkedClient.value.name);
  if (linkedProject.value?.name) parts.push(linkedProject.value.name);
  if (currentView.value?.name) parts.push(currentView.value.name);
  return parts;
});
</script>

<template>
  <header class="public-viewer-header">
    <div class="public-viewer-header__container">
      <p class="public-viewer-header__title">
        <template v-if="isBreadcrumbLoaded">
          <template v-for="(part, index) in breadcrumb" :key="index">
            <span>{{ part }}</span>
            <ChevronRightIcon v-if="index < breadcrumb.length - 1" class="public-viewer-header__divider" />
          </template>
        </template>
      </p>
      <div class="public-viewer-header__logo" aria-hidden="true">
        Viewer
      </div>
    </div>
  </header>

  <div class="public-viewer-header__mobile-breadcrumbs">
    <template v-if="isBreadcrumbLoaded">
      <template v-for="(part, index) in breadcrumb" :key="index">
        <span>{{ part }}</span>
        <ChevronRightIcon v-if="index < breadcrumb.length - 1" class="public-viewer-header__divider" />
      </template>
    </template>
  </div>
</template>


<style lang="scss" scoped>
@use '@scss/variables' as *;

.public-viewer-header {
  --_date-picker-width: 230px;

  width: 100%;
  height: 80px;
  background-color: var(--tls-primary-color);
  border-bottom: 1px solid $white-opacity-25;

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding-inline: clamp($gap-mobile, 3vw, $gap-desktop);

    @media screen and (min-width: $breakpoint-lg) {
      justify-content: space-between;
    }
  }

  &__logo {
    position: relative;
    display: block;
    width: 300px;
    height: 50px;
    overflow: hidden;
    text-indent: -3000em;
    background-image: var(--tls-logo);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;

    @media screen and (min-width: $breakpoint-lg) {
      height: 70px
    }
  }

  &__title {
    display: none;

    @media screen and (min-width: $breakpoint-lg) {
      display: -webkit-box;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      align-items: center;
      max-height: calc(1.4em * 2);  /* 2 lines of text */
      margin-bottom: 0;
      overflow: hidden;
      line-height: 1.4;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__mobile-breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
    align-items: center;
    padding: clamp($gap-mobile, 2.5vw, $gap-desktop);
    color: var(--tls-gray-800);
    background-color: var(--tls-gray-50);

    @media screen and (min-width: $breakpoint-lg) {
      display: none;
    }
  }

  &__divider {
    width: 20px;
    height: 20px;
  }
}
</style>
