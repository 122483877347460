<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { AlertVariant, CardVariant } from '@viewModels/enums';
import { dateTimeFormat } from '@utils/date';
import AlertBanner from '@components/AlertBanner.vue';
import CameraDebugItem from '@components/camera/CameraDebugItem.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import CameraStatusInformation from '@components/camera/CameraStatusInformation.vue';
import Loading from '@components/Loading.vue';

interface CameraDetail {
  label: string;
  value?: string | undefined;
  copyable?: boolean;
  format?: (value: string) => string;
  link?: string;
}

// Route and Camera Information
const route = useRoute();
const cameraId = route.params['id'] as string;
const currentCamera = ref<client.Camera>();

// View Information
const linkedView = ref<client.View | undefined>();
const linkedProject = ref<client.Project | undefined>();
const linkedClient = ref<client.Client | undefined>();

const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);

// Loading States
const isLoadingViews = ref<boolean>(false);
const isLoadingViewDetails = ref<boolean>(false);
const isLoadingCamera = ref<boolean>(true);

// Get Specific View Information
async function getView(): Promise<void> {
  isLoadingViews.value = true;

  // Fetch all views associated with the active organisation
  const viewsResult = await client.listViews({ organisation: applicationStore.activeOrganisation!.id });

  // Return early if no view is found
  if (!viewsResult.data || viewsResult.data.length === 0) {
    isLoadingViews.value = false;
    return;
  }

  // Iterate over each view returned from the API
  for (const view of viewsResult.data) {
    if (view.camera === cameraId) {
      linkedView.value = view;

      // Fetch the project and client for the current view
      linkedProject.value = await client.getProjectById({ projectId: view.project ?? '' });
      linkedClient.value = await client.getClientById({ clientId: view.client ?? '' });
      break; // Stop once the matching cameraId view is found
    }
  }

  isLoadingViews.value = false;
}

onMounted(async () => {
  try {
    currentCamera.value = await client.getCameraById({ cameraId });
    await getView();
  } catch (error) {
    console.error('Error fetching camera or view data:', error);
  } finally {
    isLoadingCamera.value = false;
  }
});

const cameraDetails = computed<CameraDetail[]>(() => [
  { label: 'Serial Number', value: currentCamera.value?.serialNumber, copyable: true },
  { label: 'ID', value: currentCamera.value?.id, copyable: true },
  { label: 'View', value: linkedView.value?.name, link: `/views/${linkedView.value?.id}`, copyable: false },
  { label: 'View ID', value: linkedView.value?.id, copyable: true },
  { label: 'Project', value: linkedProject.value?.name, copyable: true },
  { label: 'Project ID', value: linkedProject.value?.id, copyable: true },
  { label: 'Client', value: linkedClient.value?.name, copyable: true },
  { label: 'Client ID', value: linkedClient.value?.id, copyable: true },
  { label: 'System Type', value: currentCamera.value?.systemType, copyable: true },
  { label: 'Status', value: currentCamera.value?.status, copyable: false, variant: 'status'},
  // { label: 'Client Status', value: 'Active' },
  // { label: 'Location', value: 'New York, NY' },
  {
    label: 'Created At',
    value: currentCamera.value?.createdAt,
    format: (value: string): string => value ? dateTimeFormat().format(new Date(value)) : 'Not available',
    copyable: true,
  },
  // { label: 'Last Seen', value: '2024-08-28' },
  // { label: 'Last Updated', value: '2024-08-28' },
  // { label: 'Image Count', value: '10' },
  // { label: 'Status', value: 'Active' },
  // { label: 'Air Vantage ID', value: currentCamera.value?.meta?.['airVantageId'] },
  { label: 'Modem IMEI', value: currentCamera.value?.meta?.['wpImei'] as string | undefined, copyable: true },
  { label: 'Modem Serial Number', value: currentCamera.value?.meta?.['wpSerialNumber'] as string | undefined, copyable: true },
  // { label: 'Air Vantage Status', value: 'Active' },
  // { label: 'Balena Cloud ID', value: '123456789' },
  // { label: 'Balena Cloud Status', value: 'Active' },
  // { label: 'Ably ID', value: '123456789' },
  // { label: 'Ably Status', value: 'Active' },
  // { label: 'MongoDB ID', value: '1234567890-mongo' },
  // { label: 'MongoDB Status', value: 'Active' },
  // { label: 'Google Cloud ID', value: '1234567890-gcloud' },
  // { label: 'Google Cloud Status', value: 'Active' },
  // { label: 'Hub Status', value: 'Active' },
  // { label: 'Hardware API Status', value: 'Active' },
  // { label: 'Image API Status', value: 'Active' },
]);
</script>

<template>
  <ContainerCard>
    <Loading v-if="(isLoadingViews || isLoadingViewDetails || isLoadingCamera) && !linkedView" />
    <template v-else>
      <CameraStatusInformation v-if="linkedView" :view-id="linkedView.id" />

      <AlertBanner v-else :variant="AlertVariant.Warning">
        <template #mainContent>
          No view found for {{ currentCamera?.serialNumber }} camera.
        </template>
      </AlertBanner>
    </template>
  </ContainerCard>

  <ContainerCard v-if="adminMode" :variant="CardVariant.Dark" :has-border="true">
    <Loading v-if="(isLoadingViews || isLoadingViewDetails || isLoadingCamera) && !linkedView" />

    <template v-else>
      <Heading :has-bottom-margin="true" level="3">
        {{ currentCamera?.serialNumber }} Admin Panel
      </Heading>
      <CameraDebugItem :camera-details="cameraDetails" />
    </template>
  </ContainerCard>
</template>
