<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { dateTimeFormat } from '@utils/date';
import Heading from '@components/Heading.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import BadgeComponent from '@components/BadgeComponent.vue';
import { BadgeVariant, ButtonVariant } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

interface BatchFile extends client.BatchZip {
  batchDownload: string;
  batchIndex: number;
  expireAt: string;
}

// Props with extended type
const props = defineProps<{
  resource: BatchFile | null; // Accept null initially to indicate loading state
}>();

// Emits
const emit = defineEmits<{
  (e: 'onDelete', value: BatchFile['batchDownload']): void;
}>();

// Stores
const applicationStore = useApplicationStore();
const { activeUser } = applicationStore;

// Permissions
const canDownload = applicationStore.canUser(client.Entitlements.READ_BATCH_DOWNLOAD, applicationStore.activeOrganisation!);
const showDelete = applicationStore.canUser(client.Entitlements.DELETE_BATCH_DOWNLOAD, applicationStore.activeOrganisation!);

// Batch References
const batchDownloadId = ref(props.resource?.batchDownload);
const batchIndex = ref(props.resource?.batchIndex);
const batchStatus = ref<client.BatchZipStatus>(props.resource?.status || client.BatchZipStatus.INITIATED);
const batchFileName = ref(props.resource?.fileName);
const batchCreatedAt = computed(() =>
  props.resource?.createdAt
    ? dateTimeFormat(activeUser?.timezone).format(new Date(props.resource?.createdAt))
    : 'Never',
);
const batchFilesInDownload = ref(props.resource?.fileUrls.length);
const batchDownloadUrl = ref(props.resource?.signedDownloadUrl);

// Countdown timer
const batchCountDown = ref(<string>'');
// Update the countdown
const updateCountdown = (): void => {
  if (props.resource?.expireAt) {
    const now = dayjs();
    const expireAt = dayjs(props.resource.expireAt);
    const diff = expireAt.diff(now);

    if (diff > 0) {
      const duration = dayjs.duration(diff);
      batchCountDown.value = `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m`;
    } else {
      batchCountDown.value = 'Expired';
    }
  } else {
    batchCountDown.value = 'Never';
  }
};

// Badge variant mapping
const badgeVariantMapping = {
  [client.BatchZipStatus.COMPLETE]: BadgeVariant.Success,
  [client.BatchZipStatus.PROCESSING]: BadgeVariant.Danger,
  [client.BatchZipStatus.INITIATED]: BadgeVariant.Warning,
};

// Compute Badge Variants
const badgeVariant = computed(() => badgeVariantMapping[batchStatus.value] || BadgeVariant.Danger);

// Download batch zip
function downloadBatchZip(): void {
  if (batchDownloadUrl.value) {
    // Create a temporary anchor element to trigger the download
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = batchDownloadUrl.value;
    link.setAttribute('download', `${batchFileName.value}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Show success notification
    applicationStore.publishSuccessNotification({
      text: 'Downloading zip file, please check your browser’s downloads folder',
      autoCloseMs: 3000,
    });
  } else {
    console.error('Batch download URL not found');
  }
}

// Delete batch zip
function handleDeleteClick(): void {
  const batchDownloadId = props.resource?.batchDownload as BatchFile['batchDownload'];

  if (batchDownloadId) {
    emit('onDelete', batchDownloadId);
  } else {
    console.error('onDelete not emitted from DownloadCard');
  }
}

// Update countdown every minute
let intervalId: ReturnType<typeof setInterval>;
onMounted(() => {
  updateCountdown();
  intervalId = setInterval(updateCountdown, 60000); // Update every 1 minute
});

// Unmount the interval
onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId);
  }
});
</script>

<template>
  <div :class="['download-card',`download-card--${batchStatus.toLowerCase()}`]">
    <div v-if="batchStatus" class="download-card__status">
      <BadgeComponent :variant="badgeVariant">
        {{ batchStatus }}
      </BadgeComponent>
    </div>

    <div v-if="batchFileName" class="download-card__header">
      <Heading level="4" class="download-card__header--title">
        {{ batchFileName }}
      </Heading>
    </div>

    <div class="download-card__content">
      <div class="text--truncate">
        <span class="text--semibold">Images in batch:</span> {{ batchFilesInDownload }}
      </div>
      <div>
        <span class="text--semibold">Created at:</span> {{ batchCreatedAt }}
      </div>
      <div>
        <span class="text--semibold">Expires in:</span> {{ batchCountDown }}
      </div>
    </div>

    <div class="download-card__actions">
      <ButtonComponent v-if="canDownload"
                       :variant="ButtonVariant.Primary"
                       :is-icon-btn="true"
                       :icon-name="IconName.ArrowDownTrayIcon"
                       :icon-style="IconStyle.Outline"
                       aria-label="Download Batch"
                       @click="downloadBatchZip" />
      <ButtonComponent v-if="showDelete"
                       :variant="ButtonVariant.Danger"
                       :is-icon-btn="true"
                       :icon-name="IconName.TrashIcon"
                       :icon-style="IconStyle.Outline"
                       aria-label="Delete Batch"
                       @click="handleDeleteClick" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.download-card {
  display: flex;
  flex-direction: column;
  padding: $gap-mobile;
  background-color: var(--tls-gray-100);
  border-radius: 10px;
  box-shadow: inset 0 0 0 1px var(--tls-gray-300);
  transition: background-color 300ms ease, box-shadow 300ms ease;

  &--initiated {
    background-color: var(--tls-error-text);
    box-shadow: inset 0 0 0 1px var(--tls-error-color);
  }

  &--processing {
    background-color: var(--tls-warning-text);
    box-shadow: inset 0 0 0 1px var(--tls-warning-color);
  }

  &--active {
    background-color: var(--tls-success-text);
    box-shadow: inset 0 0 0 1px var(--tls-success-color);
  }

  &__status {
    align-self: flex-end;
  }

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding-block: 5px;
    margin-bottom: $margin-bottom;

    &--title {
      display: -webkit-box;
      overflow: hidden;
      line-height: 1.2;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;

      @media screen and (min-width: $breakpoint-lg) {
        flex-basis: 2.4em;
      }
    }
  }

  &__content {
    margin-bottom: $margin-bottom;
    font-size: .875rem;
    color: var(--tls-gray-600);
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>