<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { ErrorMessage, useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import Heading from '@components/Heading.vue';
import ButtonContainer from '@layouts/ButtonContainer.vue';
import { extractErrorFields, extractErrorMessage } from '@utils/errorUtils';

interface ViewCreationForm {
  name: string;
  camera: string;
  client: string;
  project: string;
}

const schema = yup.object({
  name: yup.string().required(),
  camera: yup.string().nullable(),
  client: yup.string().nullable(),
  project: yup.string().nullable(),
});

const { handleSubmit, setErrors } = useForm<ViewCreationForm>({
  validationSchema: schema,
});

const { value: nameValue } = useField<string>('name', 'name');
const { value: cameraValue } = useField<string>('camera', 'camera');
const { value: clientValue } = useField<string>('client', 'client');
const { value: projectValue } = useField<string>('project', 'project');

const router = useRouter();
const applicationStore = useApplicationStore();
const isSubmitting = ref<boolean>(false);

const cameras = await client.listCameras({ organisation: applicationStore.activeOrganisation!.id });
let filteredCameras = [...cameras.data];

const clients = await client.listClients({ organisation: applicationStore.activeOrganisation!.id });
let filteredClients = [...clients.data];

const projects = await client.listProjects({ organisation: applicationStore.activeOrganisation!.id });
let filteredProjects = [...projects.data];

const onSubmit = handleSubmit(async (values) => {
  isSubmitting.value = true;
  if (applicationStore.activeOrganisation == undefined) return;

  try {
    const response = await client.createView({
      requestBody: {
        name: values.name,
        camera: values.camera || undefined,
        organisation: applicationStore.activeOrganisation.id,
        isPublic: false,
        client: values.client || undefined,
        project: values.project || undefined,
      }
    });

    applicationStore.publishSuccessNotification({
      text: 'Successfully created view.',
      autoCloseMs: 3000,
    });

    await router.push({ name: PageNames.ViewOverview, params: { id: response.id } });
  } catch (error) {
    // Extract the error message from the error object
    const errorMessage = extractErrorMessage(error);
    const errorFields = extractErrorFields(error)

    if (errorFields) {
      setErrors(errorFields);
    }

    applicationStore.publishErrorNotification({
      text: `Error creating view: ${errorMessage}`,
    });

    return;
  } finally {
    isSubmitting.value = false;
  }
});

// Handle navigation
const cancelBtn = (): void => {
  const routerHistory = router.options.history;
  const backUrl = routerHistory.state['back'];

  // If previous route is login, navigate to /views
  if (typeof backUrl === 'string' && backUrl.startsWith('/login?continueUrl=')) {
    router.push({ name: PageNames.AdminViews });
  } else if (routerHistory.state['back']) {
    // If there's a valid previous route, go back
    router.go(-1);
  } else {
    router.push({ name: PageNames.AdminViews });
  }
};
</script>

<template>
  <form @submit="onSubmit">
    <div class="field-group">
      <div class="field-group-info">
        <Heading level="3">
          View Information
        </Heading>
        <p>Create a new view.</p>
      </div>

      <div class="fields">
        <div class="field">
          <label for="view-name">Name</label>
          <input id="view-name"
                 v-model="nameValue"
                 type="text">
          <ErrorMessage name="name" class="message message-error" as="p" />
        </div>
        <div class="field">
          <label for="model">Client</label>
          <v-select v-model="clientValue"
                    label="name"
                    :options="filteredClients"
                    :reduce="(client: client.Client) => client.id"
                    placeholder="Please select (optional)"
                    @search="
                      (search: string) => {
                        filteredClients = clients.data.filter((x) => {
                          if (!!search.length) return true;
                          return x.name.toLowerCase().includes(search.toLowerCase());
                        });
                      }
                    " />
          <ErrorMessage name="client" class="message message-error" as="p" />
        </div>

        <div class="field">
          <label for="model">Project</label>
          <v-select v-model="projectValue"
                    label="name"
                    :options="filteredProjects"
                    :reduce="(project: client.Project) => project.id"
                    placeholder="Please select (optional)"
                    @search="
                      (search: string) => {
                        filteredProjects = projects.data.filter((x) => {
                          if (!!search.length) return true;
                          return x.name.toLowerCase().includes(search.toLowerCase());
                        });
                      }
                    " />
          <ErrorMessage name="project" class="message message-error" as="p" />
        </div>
        <div class="field">
          <label for="model">Camera</label>
          <v-select v-model="cameraValue"
                    label="serialNumber"
                    :options="filteredCameras"
                    :reduce="(camera: client.Camera) => camera.id"
                    placeholder="Please select (optional)"
                    @search="
                      (search: string) => {
                        filteredCameras = cameras.data.filter((x) => {
                          if (!!search.length) return true;
                          return x.serialNumber.toLowerCase().includes(search.toLowerCase());
                        });
                      }
                    " />
          <ErrorMessage name="camera" class="message message-error" as="p" />
        </div>
      </div>
    </div>

    <ButtonContainer>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       :is-outline-btn="true"
                       @click="cancelBtn">
        Cancel
      </ButtonComponent>
      <ButtonComponent :variant="ButtonVariant.Dark"
                       :disabled="isSubmitting"
                       :type="ButtonType.Submit"
                       :is-block-btn="true">
        Create
      </ButtonComponent>
    </ButtonContainer>
  </form>
</template>
