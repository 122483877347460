<script lang="ts" setup>
import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import * as client from '@gabrielcam/api-client';
import { IconName, IconPosition } from '@viewModels/heroIcons';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import SubHeader from '@components/SubHeader.vue';
import { BreadcrumbPaths, BreadcrumbTitles } from '@viewModels/enums';
import SubNav, { SubNavItem } from '@components/SubNav.vue';
import { useApplicationStore } from '@stores/application';
import { storeToRefs } from 'pinia';

interface NavItem {
  to?: string;
  active?: boolean;
}

const currentView = ref<client.View>();
const isLoading = ref<boolean>(true);
const route = useRoute();
const viewId = route.params['id'] as string;
const breadcrumbs = ref<BreadCrumbItem[]>([]);
const breadcrumbKey = ref(0);
const subNavKey = ref(0);
const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);
const subNavItems = ref<SubNavItem[]>([]);

function updateBreadcrumbs(): void {
  if (currentView.value?.name) {
    breadcrumbs.value = [
      { title: BreadcrumbTitles.AllViews, to: BreadcrumbPaths.AllViews },
      { title: `${currentView.value.name}`, active: true },
    ];
    breadcrumbKey.value += 1; // HACK: Increment key to force re-render
  } else {
    breadcrumbs.value = [];
  }
}

// Set active state for nav items based on the current route
function setActiveState<T extends NavItem>(items: T[], currentPath: string): T[] {
  return items.map((item) => ({
    ...item,
    active: item.to === currentPath,
  }));
}

const computedNavItems = computed((): SubNavItem[] => [
  {
    title: 'Overview',
    to: `/admin/views/${viewId}/overview`,
    viewPermission: true,
    iconName: IconName.AdjustmentsHorizontalIcon,
  },
  {
    title: 'Edit',
    to: `/admin/views/${viewId}/edit`,
    viewPermission: true,
    iconName: IconName.Cog8ToothIcon,
    iconPosition: IconPosition.Left,
  },
  {
    title: 'Transfers',
    to: `/admin/views/${viewId}/transfers`,
    viewPermission: true,
    iconName: IconName.FolderIcon,
    hidden: currentView?.value?.source?.provider === 'AWS',
  },
  {
    title: 'AWS S3 [admin]',
    to: `/admin/views/${viewId}/aws`,
    viewPermission: true,
    iconName: IconName.Cog6ToothIcon,
    hidden: !adminMode.value || Boolean(currentView?.value?.camera),
  },
]);

const pageHeading = computed(() => currentView.value?.name);

onMounted(async () => {
  try {
    isLoading.value = true;
    currentView.value = await client.getViewById({ viewId });
    updateBreadcrumbs();
  } catch (error) {
    console.error('Error fetching view data:', error);
  } finally {
    isLoading.value = false;
  }
});

watchEffect(() => {
  subNavItems.value = setActiveState(computedNavItems.value, route.path);
  subNavKey.value += 1
});

watch(
  [(): unknown => route.name, (): string => route.meta['title'] as string | ''],
  () => {
    updateBreadcrumbs();
  },
  { immediate: true }
);
</script>

<template>
  <SubHeader :heading="isLoading ? 'Loading...' : pageHeading!"
             level="2" />

  <Breadcrumb :key="breadcrumbKey"
              :is-sticky="true"
              :items="breadcrumbs" />

  <SubNav :key="subNavKey"
          :is-loading="isLoading"
          :sub-nav-items="subNavItems"
          heading-level="3" />
  <router-view />
</template>
