<script lang="ts" setup>
import { PageNames } from '@viewModels/enums';

const { pageName } = defineProps<{
  pageName: string;
}>();

// Do not show the Sidebar Nav on the ViewPublic page
const showSidebar = pageName !== PageNames.ViewPublic;
</script>

<template>
  <aside v-if="showSidebar" class="app--sidebar">
    <slot name="navbar" />
  </aside>
</template>

<style lang="scss">
@use '@scss/variables' as *;

.app--sidebar {
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: 80px;

  color: var(--tls-primary-text);
  background-color: var(--tls-primary-color);
  transition: $theme-transition;

  @media screen and (min-width: $breakpoint-lg) {
    z-index: 15;
    flex-direction: column;
    width: 230px;
    height: 100%;
  }

  // Specific overrides just for the sidebar and v-select
  & .primary-v-select {
    & .v-select {
      & .vs__selected {
        @media screen and (min-width: $breakpoint-lg) {
          width: 150px;
        }
      }

      & .vs__dropdown-menu {
        @media screen and (min-width: $breakpoint-lg) {
          width: 190px;
        }

      }

      & .vs__dropdown-option {
        @media screen and (min-width: $breakpoint-lg) {
          overflow: auto;
        }
      }
    }
  }

  header {
    z-index: 16;
    width: 100%;

    @media screen and (min-width: $breakpoint-lg) {
      display: none;
    }

    a {
      &:link,
      &:visited {
        display: block;
        height: 50px;
        margin: $gap-mobile;
        text-indent: -3000em;
        background-image: var(--tls-logo);
        background-repeat: no-repeat;
        background-position: center left;
        background-size: contain;
      }

      &:hover,
      &:focus,
      &:focus-visible {
        outline: none;
        opacity: 0.8;
      }
    }
  }

  .sidebar-container {
    position: fixed;
    bottom: 0;
    z-index: 15;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;

    color: var(--tls-primary-text);
    background-color: var(--tls-primary-color);
    transition: $theme-transition;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 16;
      width: 100%;
      height: 100%;
      content: '';
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    .sidebar-container {
      flex-direction: column;
      justify-content: start;
      width: 230px;
      height: 100vh;

      &::before {
        z-index: -1;
      }
    }
  }

  &-logo {
    display: none;

    @media screen and (min-width: $breakpoint-lg) {
      z-index: 16;
      display: block;
      width: calc(100% - $gap-desktop);
      height: 70px;
      margin: $gap-desktop auto;

      background-image: var(--tls-logo);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .menu-toggle {
    z-index: 17;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    padding: 0;

    @media screen and (min-width: $breakpoint-lg) {
      display: none;
    }

    svg {
      width: 40px;
      height: 40px;
      padding: 2px;
      fill: var(--tls-primary-text);
    }
  }

  .menu-container {
    position: absolute;
    bottom: -9999px;
    left: 0;
    z-index: 15;
    width: 100%;
    visibility: hidden;

    color: var(--tls-primary-text);
    background-color: var(--tls-primary-color);
    transition: top 0.15s ease, visibility 0.15s;

    @media screen and (min-width: $breakpoint-lg) {
      position: relative;
      top: 0;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-between;
      width: inherit;
      overflow: hidden auto;
      visibility: visible;
      background-color: transparent !important;
    }
  }

  hr {
    margin: 5px 1px;
    color: inherit;
    border: none;
    border-bottom: 1px solid var(--tls-primary-300);
  }

  .menu {
    z-index: 16;
    display: flex;
    flex-direction: column;
    gap: 8px 5px;

    @media screen and (min-width: $breakpoint-lg) {
      align-items: stretch;
    }

    &-organisation {
      padding: 5px 0;
      margin-bottom: 10px;
      overflow: hidden;
      color: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-bottom: 1px solid var(--tls-primary-300);
    }

    svg.menu-icon {
      width: 20px;
      height: 20px;
    }

    svg.arrow-icon {
      width: 20px;
      height: 20px;
      margin-left: auto;
      transition: transform 0.3s ease;
    }

    svg.rotate-180 {
      transform: rotate(180deg);
    }

    li,
    div.menu-footer {
      list-style: none;

      @media screen and (min-width: $breakpoint-lg) {
        display: block;
      }

      .menu-dropdown {
        display: flex;
        column-gap: 8px;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-height: 36px;
        padding: 10px;
        margin: auto;
        overflow: hidden;
        text-decoration: none;
        white-space: nowrap;
        border: none;
        border-radius: 5px;
        outline: 1px solid transparent;

        color: var(--tls-primary-text);
        background-color: var(--tls-primary-700);
        box-shadow: inset 0 0 0 1px var(--tls-primary-800);
        transition: $theme-transition;

        &:hover,
        &:focus,
        &:active {
          cursor: pointer;
          outline: 1px solid transparent;
          background-color: var(--tls-primary-500);
        }

        &.has-submenu {
          margin-bottom: -6px;
          cursor: default;
          background-color: unset;
        }

        .menu-header-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .icon-container {
          margin-left: auto;
        }
      }

      &.menu-header {
        padding: 5px 0;
        margin-bottom: 15px;
        color: inherit;
        border-bottom: 1px solid var(--tls-primary-500);

        @media screen and (min-width: $breakpoint-lg) {
          display: block;
          padding-left: 34px;
          margin: 10px 0 15px -17px !important;
          font-size: 0.85em;
          color: inherit;
          opacity: 0.5;

          a {
            &:link,
            &:visited {
              padding: 0;
              margin: 0;
              pointer-events: none;
            }
          }

          @media screen and (height <= 600px) {
            height: 1px;
            margin: 4px 0 6px;
            text-indent: -3000px;
            background: $white-opacity-50;
          }
        }
      }

      a {
        &:link,
        &:visited {
          display: flex;
          column-gap: 8px;
          align-items: center;
          justify-content: flex-start;
          padding: 8px 10px;
          overflow: hidden;
          text-decoration: none;
          white-space: nowrap;
          border-radius: 5px;
          outline: 1px solid transparent;

          color: var(--tls-primary-text);
          background-color: var(--tls-primary-color);
          transition: $theme-transition;

          @media screen and (min-width: $breakpoint-lg) {
            @media screen and (height <= 700px) {
              padding: 8px 46px 8px 42px;
            }

            @media screen and (height <= 640px) {
              padding: 6px 48px 6px 40px;
            }
          }
        }

        &:active,
        &:hover,
        &:focus,
        &:focus-visible,
        &.router-link-exact-active {
          outline: 1px solid transparent;
          background-color: var(--tls-primary-500);
          box-shadow: inset 0 0 0 1px var(--tls-primary-800);
        }

        &.router-link-exact-active {
          pointer-events: none;
        }
      }

      .submenu {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        padding: 8px 2px 8px 27px;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    &.menu-primary {
      padding: 30px 20px 0;
      line-height: 1;
      list-style: none;

      @media screen and (min-width: $breakpoint-lg) {
        padding: 0 20px;
      }
    }

    &.menu-secondary {
      position: relative;
      z-index: 15;
      padding: 10px 25px;
      margin-top: 30px;
      border-top: 1px solid var(--tls-primary-800);
      background-color: var(--tls-primary-700);

      @media screen and (width >= $breakpoint-lg) {
        padding: 20px 15px;
      }

      .menu-item {
        display: flex;
        column-gap: 8px;
        align-items: center;
      }

      .menu-footer a {
        margin: 0 !important;
        padding: 0 !important;
        background-color: var(--tls-primary-700)!important;
        transition: $theme-transition;

        @media screen and (width >= $breakpoint-lg) {
          padding: 8px 10px !important;
        }

        &:hover {
          box-shadow: inset 0 0 0 1px var(--tls-primary-700);
          background-color: var(--tls-primary-color)!important;
        }
      }

      svg.footer-icon {
        width: 24px;
        height: 24px;
      }

      li a:hover,
      li a:focus,
      li a:focus-visible {
        background-color: var(--tls-primary-700);
      }
    }
  }
}

.app-container.mobile-nav-open {
  .app--sidebar {
    .menu-container {
      @media screen and (max-width: $breakpoint-lg) {
        bottom: 80px;
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        visibility: visible;
        border-radius: 15px 15px 0 0;
        transition:
          bottom 200ms ease,
          visibility 0.15s ease;
      }
    }
  }
}
</style>
