<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useRoute } from 'vue-router';
import { ServerStackIcon, ChartBarIcon, BellIcon, CameraIcon, BuildingOfficeIcon } from '@heroicons/vue/24/solid';
import prettyBytes from 'pretty-bytes';
import { BadgeVariant } from '@viewModels/enums';
import Loading from '@components/Loading.vue';
import BadgeComponent from '@components/BadgeComponent.vue';
import ViewUsageChart from '@components/view/ViewStorageUsageChart.vue';
import ViewRecentActivity from '@components/view/ViewRecentLogActivity.vue';

const route = useRoute();
const viewId = route.params['id'] as string;
const isLoadingView = ref<boolean>(true);
const isLoadingViewStatsLatest = ref<boolean>(true);
const view = ref<client.View>();
const camera = ref<client.Camera>();
const latestStat = ref<client.ViewStat>();
const statusVariantMap = {
  [client.ViewStatus.ACTIVE]: { variant: BadgeVariant.Success, label: client.ViewStatus.ACTIVE },
  [client.ViewStatus.INACTIVE]: { variant: BadgeVariant.Danger, label: client.ViewStatus.INACTIVE },
  [client.ViewStatus.ARCHIVE]: { variant: BadgeVariant.Warning, label: client.ViewStatus.ARCHIVE },
};

const fetchViewData = async (): Promise<void> => {
  isLoadingView.value = true;
  view.value = await client.getViewById({
    viewId: viewId,
    expand: [
      client.Resources_TransferSettings.TRANSFER_SETTINGS,
    ],
    includes: [
      client.Resources_Client.CLIENT,
      client.Resources_Project.PROJECT
    ]
  });

  if (view.value.camera) {
    camera.value = await client.getCameraById( { cameraId: view.value.camera } );
  }
  isLoadingView.value = false;
};

const fetchViewStatsData = async (): Promise<void> => {
  isLoadingViewStatsLatest.value = true;
  try {
    latestStat.value = await client.getViewByIdStatLatest({ viewId: viewId });
  } catch {}
  isLoadingViewStatsLatest.value = false;
};

/**
 * Computed values
 */
const isAwsStorage = computed(() => view?.value?.source?.provider === 'AWS')

const totalImages = computed(() => {
  // Get the value from the stats
  if (latestStat?.value?.storageImageCount) return  latestStat.value.storageImageCount;
  // AWS sources won't have stats, use the count on the view
  if (view?.value?.imageCount) return view.value.imageCount;
  return 'n/a';
})

const usedStorageBytes = computed(() => {
  if (isAwsStorage.value) return 'n/a';
  if (latestStat?.value?.storageImageSizeBytes) return prettyBytes(latestStat.value.storageImageSizeBytes);
  return 'n/a';
})

const clientProject = computed(() => {
  let returnValue = 'n/a';
  if (view?.value?.includes?.client?.name) {
    returnValue = view.value.includes.client?.name;

    if (view?.value?.includes?.project?.name) {
      returnValue = `${returnValue} / ${view?.value?.includes?.project?.name}`
    }
  }
  return returnValue;
})

const cameraName = computed(() => {
  if (isAwsStorage.value) return 'Generic InSite';
  if (camera?.value?.serialNumber) return camera.value.serialNumber
  return 'n/a';
})

/**
 * Lifecycle
 */
onMounted(async () => {
  await fetchViewData();
  await fetchViewStatsData();
});
</script>

<template>
  <Loading v-if="isLoadingView || isLoadingViewStatsLatest" />

  <template v-else>
    <div class="status-components">
      <div class="status-components--small-panel">
        <ChartBarIcon class="status-components__icon" />
        <div>
          <div class="status-components__title">
            Total images
          </div>
          {{ totalImages }}
        </div>
      </div>

      <div class="status-components--small-panel">
        <ServerStackIcon class="status-components__icon" />
        <div>
          <div class="status-components__title">
            Used storage
          </div>
          {{ usedStorageBytes }}
        </div>
      </div>

      <div class="status-components--small-panel">
        <BuildingOfficeIcon class="status-components__icon" />
        <div>
          <div class="status-components__title">
            Client / Project
          </div>
          {{ clientProject }}
        </div>
      </div>

      <div class="status-components--small-panel">
        <CameraIcon class="status-components__icon" />
        <div>
          <div class="status-components__title">
            Camera
          </div>
          {{ cameraName }}
        </div>
      </div>

      <div class="status-components--small-panel">
        <BellIcon class="status-components__icon" />
        <div>
          <span class="status-components__title">Status</span>
          <br>
          <BadgeComponent v-if="statusVariantMap[view!.status as client.ViewStatus]"
                          :is-pill="true"
                          :variant="statusVariantMap[view!.status as client.ViewStatus].variant">
            {{ statusVariantMap[view!.status as client.ViewStatus].label }}
          </BadgeComponent>
        </div>
      </div>
    </div>

    <div class="status-components">
      <div class="status-components--large-panel">
        <div class="status-components__title">
          Storage history
        </div>
        <br>
        <ViewUsageChart :view-id="viewId" style="height: 300px;" />
      </div>

      <div class="status-components--large-panel">
        <div class="status-components__title">
          Recent activity
        </div>
        <br>
        <ViewRecentActivity :view-id="viewId" />
      </div>
    </div>
  </template>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.status-components {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  gap: 30px;
  margin-bottom: $margin-bottom;

  &--large-panel {
    padding: clamp($gap-mobile, 1.5vw, $gap-desktop);
    color: var(--tls-gray-800);
    background-color: var(--tls-gray-50);
    border-radius: 6px;
    box-shadow: 0 0 0 1px var(--tls-gray-400);
  }

  &--small-panel {
    padding: clamp($gap-mobile, 1.5vw, $gap-desktop);
    color: var(--tls-gray-800);
    background-color: var(--tls-gray-50);
    border-radius: 6px;
    box-shadow: 0 0 0 1px var(--tls-gray-400);
    display: grid;
    grid-template-columns: 30px 1fr;
    gap: clamp(5px, 1vw, 10px);
    align-items: center;
    justify-content: center;
  }

  &__icon {
    width: 28px;
  }

  &__title {
    font-weight: 600;
  }
}

</style>
