<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import * as client from '@gabrielcam/api-client';
import { dateTimeFormat } from '@utils/date';
import { CardVariant } from '@viewModels/enums';
import ContainerCard from '@components/cards/ContainerCard.vue';
import HorizontalRule from '@components/HorizontalRule.vue';
import BatteryPanel from '@components/view_dashboard/BatteryPanel.vue';
import Heading from '@components/Heading.vue';
import { extractErrorMessage } from '@utils/errorUtils';
import { isClientResource, isProjectResource } from '@utils/typeguards';
import Loading from '@components/Loading.vue';

// Enums
enum Status {
  On = 'ON',
  Off = 'OFF',
}

// Interfaces
interface ViewId {
  viewId: string;
}

// Props
const props = defineProps<ViewId>();

// Loading state
const isLoading = ref<boolean>(true);

// ** View Information **
const viewDetails = ref<client.View | null>(null);
const clientName = ref<string>('No client');
const projectName = ref<string>('No project');
const viewStatus = ref<client.ViewStatus>(client.ViewStatus.INACTIVE);
const imageCount = ref<string>('0');
const isPublic = ref<boolean>(false);

const lastImageCapturedFormatted = computed(() => {
  const lastCapturedUtc = viewDetails.value?.lastCapturedUtc;
  return lastCapturedUtc
    ? dateTimeFormat().format(new Date(lastCapturedUtc))
    : 'Not available';
});

// ** Camera Information **
const currentCamera = ref<client.Camera | null>(null);

// ** Camera Stats **
const cameraStats = ref<client.CameraStat | null>(null);
const cameraOn = ref<boolean>(false);
const piOn = ref<boolean>(false);
const heaterOn = ref<boolean>(false);
const gnssLatitude = ref(0);
const gnssLongitude = ref(0);
const lt4015BatteryVoltage = ref(0);
const lt4015InputVoltage = ref(0);
const uptime = ref<string>('Not available');
const imagesTaken = ref<string>('0');
const imagesOnCamera = ref<string>('0');
const imagesOnLocal = ref<string>('0');

const getCameraUpdatedAt = computed(() => {
  const cameraUpdatedAt = cameraStats.value?.updatedAt;
  return cameraUpdatedAt
    ? dateTimeFormat().format(new Date(cameraUpdatedAt))
    : 'Not available';
});

// ** System Type **
const GABRIELCAMV1 = computed(() => currentCamera.value?.systemType === client.SupportedSystems.GABRIELCAMV1);

async function fetchAllData(): Promise<void> {
  isLoading.value = true;

  try {
    await fetchViewDetails();
    await fetchCameraDetails();
    await fetchCameraStats();
  } catch (error) {
    console.error('Error fetching camera or view data:', error);
  } finally {
    isLoading.value = false;
  }
}

async function fetchViewDetails(): Promise<void> {
  try {
    // Fetch view details without includes first to handle 404 errors gracefully
    const details = await client.getViewById({ viewId: props.viewId });
    // console.info('Fetched view details:', details);

    if (!details) {
      console.warn('No view details found for this view');
      return;
    }

    viewDetails.value = details as client.View;

    // Attempt to fetch client and project includes separately
    await fetchClientAndProject();
  } catch (error) {
    console.error('Error fetching view details:', error);
  }
}

async function fetchClientAndProject(): Promise<void> {
  try {
    const detailsWithIncludes = await client.getViewById({
      viewId: props.viewId,
      includes: [client.Resources_Client.CLIENT, client.Resources_Project.PROJECT],
    });

    if (detailsWithIncludes.includes) {
      // Use type guards to confirm resource types
      clientName.value = isClientResource(detailsWithIncludes.includes.client)
        ? detailsWithIncludes.includes.client.name
        : 'No client';

      projectName.value = isProjectResource(detailsWithIncludes.includes.project)
        ? detailsWithIncludes.includes.project.name
        : 'No project';
    }
  } catch (error) {
    const errorMessage = extractErrorMessage(error);
    if (errorMessage.includes('View does not exist')) {
      console.warn("View found, but no associated client/project");
      clientName.value = 'No client';
      projectName.value = 'No project';
    } else {
      console.error('Unexpected error fetching client/project data with includes:', errorMessage);
    }
  }
}

async function fetchCameraDetails(): Promise<void> {
  if (!viewDetails.value?.camera) {
    console.warn('No camera ID associated with this view');
    return;
  }

  try {
    currentCamera.value = await client.getCameraById({ cameraId: viewDetails.value.camera });
  } catch (error) {
    console.error('Error fetching camera data:', error);
  }
}

async function fetchCameraStats(): Promise<void> {
  if (!viewDetails.value?.camera) {
    console.warn('No camera ID associated with this view for fetching stats');
    return;
  }

  try {
    const stats = await client.getCameraByIdStatLatest({
      cameraId: viewDetails.value.camera,
    } as client.GetCameraByIdStatLatestData);

    if (stats) {
      cameraStats.value = stats as client.CameraStat;
    } else {
      console.warn('No camera stats available');
    }
  } catch (error) {
    console.error('Error fetching camera stats:', error);
  }
}


// Watch for changes in cameraStats
watch(() => cameraStats.value, (cameraStatsNew: client.CameraStat | null) => {
  if (cameraStatsNew) {
    cameraOn.value = cameraStatsNew.cameraOn ?? Status.Off;
    piOn.value = cameraStatsNew.piOn ?? Status.Off;
    heaterOn.value = cameraStatsNew.heaterOn ?? Status.Off;
    gnssLatitude.value = cameraStatsNew.gnssLatitude ?? 0;
    gnssLongitude.value = cameraStatsNew.gnssLongitude ?? 0;
    lt4015BatteryVoltage.value = cameraStatsNew.lt4015BatteryVoltage ?? 0;
    lt4015InputVoltage.value = cameraStatsNew.lt4015InputVoltage ?? 0;
    uptime.value = cameraStatsNew?.upTime ?? 'Not available';
    imagesTaken.value = cameraStatsNew.imagesTaken?.toLocaleString() ?? '0';
    imagesOnCamera.value = cameraStatsNew.imagesOnCamera?.toLocaleString() ?? '0';
    imagesOnLocal.value = cameraStatsNew.imagesOnLocal?.toLocaleString() ?? '0';
  }
});

// Watch for changes in viewDetails
watch(() => viewDetails.value, (viewDetailsNew) => {
  if (viewDetailsNew) {
    viewStatus.value = viewDetailsNew.status || client.ViewStatus.INACTIVE;
    imageCount.value = viewDetailsNew.imageCount?.toLocaleString() ?? '0';
    isPublic.value = viewDetailsNew.isPublic ?? false;
  }
});

onMounted(fetchAllData);
</script>

<template>
  <div class="status-header">
    <Heading level="3">
      System Status
    </Heading>
    <span class="status-header__updated">(Last Updated at {{ getCameraUpdatedAt }})</span>
  </div>

  <ContainerCard :variant="CardVariant.Light"
                 :has-shadow="false"
                 :has-border="true">
    <template v-if="GABRIELCAMV1">
      <BatteryPanel :view-id="props.viewId" />
      <HorizontalRule />
    </template>

    <div class="status-details-container">
      <Heading level="4" :has-bottom-margin="true">
        Details
      </Heading>

      <Loading v-if="isLoading" />

      <template v-else>
        <div class="status-details">
          <div class="status-details__item">
            <div class="status-details__item-container">
              <span class="status-details__item--title">Status:</span>
              <div class="text--capitalize">
                {{ viewStatus.toLowerCase() }}
              </div>
            </div>
          </div>
          <div class="status-details__item">
            <div class="status-details__item-container">
              <span class="status-details__item--title">Project:</span>
              <div>{{ projectName }}</div>
            </div>
          </div>
          <div class="status-details__item">
            <div class="status-details__item-container">
              <span class="status-details__item--title">Client:</span>
              <div>{{ clientName }}</div>
            </div>
          </div>
          <div class="status-details__item">
            <div class="status-details__item-container">
              <span class="status-details__item--title">Uptime:</span>
              <div>{{ uptime }}</div>
            </div>
          </div>
          <div class="status-details__item">
            <div class="status-details__item-container">
              <span class="status-details__item--title">Images Uploaded:</span>
              <div>{{ imageCount }}</div>
            </div>
          </div>
          <div class="status-details__item">
            <div class="status-details__item-container">
              <span class="status-details__item--title">Last Image Captured:</span>
              <div>{{ lastImageCapturedFormatted }}</div>
            </div>
          </div>
          <div class="status-details__item">
            <div class="status-details__item-container">
              <span class="status-details__item--title">Publicly Viewable:</span>
              <div>{{ isPublic ? 'Yes' : 'No' }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </ContainerCard>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.status-header {
  display: flex;
  flex-direction: column;
  margin-bottom: $margin-bottom;

  @media screen and (min-width: $breakpoint-lg) {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }

  &__updated {
    font-size: 12px;
    color: var(--tls-gray-600);
  }
}

.status-details {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: clamp($gap-mobile, 3vw, $gap-desktop);
  font-size: 14px;

  &-container {
    container-type: inline-size;
    container-name: status-details;
  }

  // Using a @container to limit the width because of the sidenav
  @container status-details (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
    padding: 10px 20px;
    border-radius: 6px;
    box-shadow: 0 0 0 1px var(--tls-gray-400);

    // Hide the top border on the second item when we have two columns
    &__item {
      &:nth-child(2) {
        border-top: 0;
      }
    }
  }

  &__item {
    display: flex;
    gap: 5px 20px;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid var(--tls-gray-200);

    // Hide the top border on the first item when we have one column
    &:nth-child(1) {
      border-top: 0;
    }

    &--title {
      font-weight: 600;
    }
  }

  &__item-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
}
</style>
