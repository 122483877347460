<script lang="ts" setup>
import { computed } from 'vue';

import { BadgeVariant } from '@viewModels/enums';

interface BadgeProps {
  variant?: BadgeVariant;
  isPill?: boolean;
  isLarge?: boolean;
}
// Deconstruct Props
const { variant, isPill, isLarge } = defineProps<BadgeProps>();

// Badge classes
const badgeClasses = computed(() => {
  return ['badge', `badge--${variant || 'primary'}`, isPill && 'badge--pill', isLarge && 'badge--large'];
});
</script>

<template>
  <span :class="badgeClasses">
    <slot />
  </span>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: .2em 1em;
  font-size: 0.75em;
  font-weight: 500;
  line-height: normal;
  color: var(--tls-gray-50);
  text-transform: uppercase;
  vertical-align: baseline;
  cursor: default;
  border-radius: 5px;
  transition: background-color 0.3s linear;

  &--primary {
    color: var(--tls-primary-text);
    background-color: var(--tls-primary-color);
  }

  &--secondary {
    color: var(--tls-secondary-text);
    background-color: var(--tls-secondary-color);
  }

  &--success {
    color: var(--tls-success-text);
    background-color: var(--tls-success-color);
  }

  &--danger {
    color: var(--tls-error-text);
    background-color: var(--tls-error-color);
  }

  &--warning {
    color: var(--tls-warning-text);
    background-color: var(--tls-warning-color);
  }

  &--info {
    color: var(--tls-info-text);
    background-color: var(--tls-info-color);
  }

  &--light {
    color: var(--tls-gray-800);
    background-color: var(--tls-gray-300);
  }

  &--dark {
    color: var(--tls-gray-50);
    background-color: var(--tls-gray-700);
  }

  &--black {
    color: var(--tls-gray-50);
    background-color: var(--tls-gray-900);
  }

  &--transparent {
    background-color: transparent;
  }

  &--outline {
    color: var(--tls-gray-600);
    background-color: transparent;
    box-shadow: 0 0 0 1px var(--tls-gray-400);;
  }

  &--pill {
    // padding: 0.175em 0.75em;
    border-radius: 50rem;
  }

  &--large {
    font-size: 1rem;
  }

  &:empty {
    display: none;
  }
}
</style>
